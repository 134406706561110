/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.recipe-search {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  box-shadow: 0 4px 8px #cecece;
  position: relative; }

.recipe-search__close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 1rem; }

.steps {
  margin-top: 1rem; }

.card {
  cursor: grabbing;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  background: #FAFAFA;
  border-left: 4px solid #D97225;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold; }
  .card span {
    font-size: 0.8rem;
    font-weight: normal; }

.add {
  cursor: pointer;
  margin-top: 1rem;
  padding: 1rem;
  text-transform: uppercase;
  background: #FAFAFA;
  font-size: 1rem;
  font-weight: bold;
  color: #D97225;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  .add i {
    margin-right: 2rem; }

.remove {
  text-align: right;
  cursor: pointer; }
  .remove:hover {
    color: #D97225; }

.send {
  text-align: center;
  border: 1px solid #D97225;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.5rem;
  color: #D97225; }

.hint {
  text-align: center;
  opacity: 0.5;
  font-size: 0.8rem; }

.steps-container {
  padding: 0.5rem; }

.info {
  display: flex;
  flex-direction: column; }
  .info span {
    color: #4A4A4A; }

.calc-container {
  position: relative; }

.calc, .calc--active {
  position: absolute;
  font-size: 30px;
  right: 15px;
  bottom: 15px;
  color: #ccc; }

.calc--active {
  color: #D97225;
  cursor: pointer; }
  .calc--active:hover {
    filter: brightness(80%); }
