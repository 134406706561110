.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100%; }

.tabs {
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 3.5rem; }

.tabs-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%; }

.tab {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 3.5rem;
  font-weight: bold;
  border: none;
  outline: 0;
  border-radius: 0; }

.panel {
  display: flex;
  flex: 1;
  overflow-x: hidden;
  height: 100%;
  align-items: flex-start;
  min-height: 100%; }

.bottom-margin-separator {
  height: 3.5rem;
  width: 100%;
  flex: 1 0 auto; }
