/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.spinner {
  margin: 10px;
  animation: rotate 0.8s infinite linear;
  border: 4px solid #D97225;
  border-right-color: transparent;
  border-radius: 50%; }

.white {
  border: 4px solid white;
  border-right-color: transparent; }

.small {
  border-top-width: 2px;
  border-left-width: 2px;
  border-bottom-width: 2px; }

.spinner--absolute {
  position: absolute;
  top: 50%;
  left: 50%; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
