/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.container {
  margin: 2rem 0 0 2rem;
  display: flex;
  width: calc(100% - 4rem); }

.items-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end; }
  @media only screen and (max-width: 64em) {
    .items-container {
      width: 100%; } }

.home {
  height: 1.8rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-right: 0.2rem;
  color: #D97225 !important;
  padding: 0.2rem 3rem 0.2rem 3rem;
  background-color: #F7F7F7;
  display: block;
  padding: 0.2rem 0.5rem !important; }

.item {
  white-space: nowrap;
  height: 1.8rem;
  display: flex;
  font-weight: bold;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-right: 0.2rem;
  justify-content: center;
  color: #D97225 !important;
  padding: 0.2rem 2rem 0.2rem 3rem;
  background-color: #F7F7F7;
  position: relative;
  margin-left: .2rem; }

.item::after {
  content: "";
  right: -.9rem;
  position: absolute;
  color: white;
  width: 0;
  height: 0;
  border-top: .9rem solid transparent;
  border-bottom: .9rem solid transparent;
  border-left: 0.9rem solid #F7F7F7; }

.item:last-of-type {
  padding: 0.2rem 2rem 0.2rem 2rem; }
  .item:last-of-type::before {
    border: none; }

.item:first-of-type {
  padding: 0.2rem 2rem 0.2rem 2rem; }
  .item:first-of-type::after {
    border: none; }

.item::before {
  content: "";
  left: 0;
  position: absolute;
  color: white;
  width: 0;
  height: 0;
  border-top: .9rem solid transparent;
  border-bottom: .9rem solid transparent;
  border-left: .9rem solid white; }

@media only screen and (max-width: 64em) {
  .item.last-of-mobile {
    padding: 0.2rem 2rem 0.2rem 2rem;
    flex: 1; }
    .item.last-of-mobile::before {
      border: none; } }
