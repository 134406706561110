/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.container {
  display: flex;
  flex: 1;
  height: 100%; }

.panel, .right-panel {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center; }

.right-panel {
  background-color: #f3f3f3; }

.split-horizontal {
  flex-direction: row; }

.split-vertical {
  flex-direction: column; }

.logo {
  display: block;
  height: 24rem;
  margin: 0 auto; }
  @media only screen and (max-width: 64em) {
    .logo {
      height: auto;
      width: 15rem; } }

.title {
  margin-top: 2rem;
  font-size: 2.5rem; }

.error {
  display: flex;
  color: #910400;
  height: 4rem;
  justify-content: center;
  align-items: cente; }

.form {
  display: flex;
  flex-direction: column; }
  .form input {
    text-align: center; }
  .form input:focus, .form input:focus {
    outline: none; }
  @media only screen and (max-width: 64em) {
    .form {
      padding: 1rem; } }

.button-border {
  text-transform: uppercase;
  background: #555;
  color: white;
  border: 1px solid #DEDEDE; }
