/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.container {
  width: 100%;
  align-content: center;
  justify-content: center;
  display: flex; }
  .container span {
    padding: 0.75rem;
    opacity: 0.2; }

.current {
  opacity: 1 !important; }

.enabled {
  color: #D97225;
  opacity: 1 !important;
  cursor: pointer; }
  .enabled:hover {
    background-color: #D97225;
    color: white;
    border-radius: 4px; }
