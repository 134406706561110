/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.main {
  width: 100%;
  height: calc(100% + 3.5rem);
  margin-top: -3.5rem;
  display: flex; }
  @media only screen and (max-width: 64em) {
    .main {
      flex-direction: column;
      background-color: #ECECEC; } }

.logo-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; }
  .logo-container img {
    height: 24rem; }
  @media only screen and (max-width: 64em) {
    .logo-container {
      flex: .5; }
      .logo-container img {
        height: auto;
        width: 12rem;
        padding: 1rem; } }

.selector {
  flex: 1;
  background-color: #ECECEC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-left: 3px solid #4A4A4A; }
  @media only screen and (max-width: 64em) {
    .selector {
      border: 0px solid #4A4A4A; } }

.card {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 1.5rem 0.5rem;
  margin: 0rem 0rem;
  justify-content: flex-start;
  text-align: left;
  border-left: 3px solid #4A4A4A;
  margin-left: -3px; }
  .card:hover {
    border-left-color: #D97225; }
  @media only screen and (max-width: 64em) {
    .card {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 1.5rem 1.5rem; } }

.card .icon {
  padding: 0.15rem 1.8rem;
  font-size: 1.4rem;
  color: #4A4A4A; }
  @media only screen and (max-width: 64em) {
    .card .icon {
      padding: 0rem 1.5rem 0 1rem; } }

.card:hover .icon {
  color: #D97225; }

.menu-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #4A4A4A;
  text-align: left; }

.menu-title {
  font-weight: bold;
  font-size: 1.3rem; }

.menu-subtitle {
  margin-top: 0.4rem;
  font-size: 1rem;
  line-height: 1.2rem;
  opacity: .8; }
  @media only screen and (max-width: 64em) {
    .menu-subtitle {
      display: none; } }

.card:hover .menu-info {
  color: #D97225; }
