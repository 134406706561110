/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.container {
  padding: 0.5rem; }

.card, .select {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  background: #FAFAFA;
  border: 1px solid #F7F7F7;
  border-radius: 3px;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: 0.1s box-shadow ease-in, 0.1s transform ease-in-out; }
  .card span, .select span {
    font-size: 0.8rem;
    font-weight: normal; }
  .card:hover, .select:hover {
    box-shadow: 0px 2px 7px #0003;
    transform: translateY(-2px); }

.button {
  cursor: pointer; }
  .button:hover i {
    color: #D97225; }

.subtle {
  font-size: 0.8rem;
  font-weight: normal;
  align-items: center;
  text-transform: uppercase;
  color: #9B9B9B;
  justify-content: center !important; }

.select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .select:hover {
    transition: box-shadow 0.1s ease-in;
    box-shadow: 0px 1px 5px #cecece; }

.check {
  color: #4A4A4A; }

.active {
  color: #D97225; }

.select-content {
  display: flex;
  flex-direction: column; }

.status {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem; }

.accessory {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 1.2rem;
  color: #4A4A4A; }

.tag {
  background-color: #4A4A4A;
  color: white !important;
  width: auto !important;
  padding: 0.1rem 0.5rem;
  border-radius: 4px;
  margin-top: 2px;
  font-weight: bold;
  font-size: 0.7rem !important;
  text-transform: uppercase; }
