/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.reload {
  color: #D97225;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer; }
