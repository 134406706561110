/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.table {
  overflow-x: auto; }

.header {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem; }

.item, .item-print {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  background: #FAFAFA;
  min-height: 2rem;
  padding: .5rem; }
  .item span, .item-print span {
    padding: 0.5rem 0.25rem; }

.item-print {
  background: white !important;
  border-bottom: 1px solid #777;
  border-right: 0 !important; }

.multiline {
  display: flex;
  flex-direction: column;
  line-height: 0.7rem; }

.main {
  font-weight: bold; }

.detail {
  font-size: 0.75rem; }

.detail-lybra {
  font-size: 0.75rem;
  background-color: #F5A623;
  color: white;
  border-radius: 4px 4px 0 0;
  text-align: center; }

.report {
  color: #D97225;
  padding: 4px 0;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0 0 4px 4px;
  background-color: #F5A62340; }
  .report:visited {
    color: #D97225; }
  .report:hover {
    color: white; }
