/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.info span {
  color: #4A4A4A; }

.info-light {
  color: #9B9B9B;
  margin-top: .5rem;
  font-size: 1rem; }

.firmware {
  margin-top: 1rem;
  background-color: #F7F7F7;
  padding: 1rem; }

.subtitle {
  font-style: italic;
  font-weight: normal; }

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem; }

.dropzone {
  width: 100%;
  height: 50px;
  border-width: 2px;
  border-color: #9B9B9B;
  border-style: dashed;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center; }

.dropzone--active {
  border-color: #D97225 !important; }

.inline-button {
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8rem;
  border-radius: 3px;
  color: #D97225 !important;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  padding: 0.3rem 0;
  margin-top: 0.5rem; }

.inline-button__header {
  padding: 1rem 0;
  text-align: right;
  margin-top: 0; }

.notes {
  background: #F7F7F7;
  padding: 1rem;
  border-radius: 2px;
  text-transform: none;
  font-style: italic; }

.no-firmware {
  font-size: 0.8rem;
  font-style: italic; }

.accessory, .download, .send, .delete, .info {
  width: 20px;
  height: 20px;
  margin-left: 6px;
  font-size: 1.2rem !important;
  cursor: pointer; }

.accessory-container {
  text-align: right; }

.space-above {
  margin-top: 3rem; }

.download:hover {
  color: #D97225 !important; }

.send:hover {
  color: #004681 !important; }

.delete:hover {
  color: #e25252 !important; }

.info:hover {
  color: #5b8ccc !important; }

.more {
  color: #9B9B9B;
  font-size: 0.8rem;
  font-style: italic;
  cursor: pointer;
  text-align: right; }

.file-remove {
  margin-top: 0.5rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  color: #D97225 !important;
  font-size: 0.8rem !important; }

.feedback-container {
  overflow: auto;
  background: #fafafa;
  position: absolute;
  top: 2rem;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  padding: 1rem; }

.feedback-item {
  font-weight: bold;
  font-size: 0.9rem; }

.feedback-error {
  color: #910400; }

.feedback-done {
  color: #76B45C; }

.notes-text {
  color: black;
  text-transform: none;
  font-size: 0.8rem;
  resize: none; }
