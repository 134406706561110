/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.info {
  display: flex;
  flex-direction: column; }
  .info span {
    color: #4A4A4A; }

.header {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem; }

.item {
  border-bottom: 1px solid #777;
  font-size: 0.9rem;
  display: flex;
  margin-top: 0.1rem !important;
  align-items: center; }
  .item span {
    padding: 0.5rem 0.25rem; }
