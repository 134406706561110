@charset "UTF-8";
/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.container {
  z-index: 5000;
  width: 100%;
  height: 3.5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #131313;
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 64em) {
    .container {
      height: auto;
      overflow-y: initial;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; } }

.container.closed {
  height: 3.5rem; }
  @media only screen and (max-width: 64em) {
    .container.closed {
      overflow-y: hidden; } }

.container-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3.5rem; }
  @media only screen and (max-width: 64em) {
    .container-logo {
      width: 100%;
      justify-content: space-between; } }

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 4rem;
  cursor: pointer;
  background-color: #131313; }
  .logo:hover {
    filter: invert(10%); }
  .logo img {
    max-height: 2.5rem; }

.menu-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 4rem;
  cursor: pointer;
  background-color: #131313; }
  .menu-toggle:hover {
    filter: invert(10%); }
  .menu-toggle span {
    font-size: 1.8rem;
    color: white; }

.dropdown {
  position: absolute;
  background: #333;
  top: 3.5rem;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none; }
  .dropdown:hover  {
    filter: none !important; }
  .dropdown li {
    padding: 0.5rem;
    color: #ffffffcc;
    margin: 0;
    font-size: 0.9rem; }
    .dropdown li:hover {
      background: #777; }
  @media only screen and (max-width: 64em) {
    .dropdown {
      overflow-y: auto;
      max-height: 20rem;
      border-radius: 0 0 5px 0px; } }

.search-container {
  display: flex;
  align-items: center;
  color: white; }
  .search-container .search-button {
    cursor: pointer; }
  .search-container input {
    outline: none;
    background: none !important;
    padding: 0.5rem !important;
    border: none !important;
    margin: 0 !important;
    color: white !important; }
    .search-container input::placeholder {
      color: #eee; }

.menu-item {
  height: 3.5rem;
  min-height: 3.5rem;
  padding: 0 2rem;
  font-weight: bold;
  color: #9B9B9B;
  background-color: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  padding-top: 4px;
  border-bottom: 4px solid #131313;
  box-sizing: border-box; }
  @media only screen and (max-width: 64em) {
    .menu-item {
      width: 3.5rem; } }
  .menu-item:hover {
    color: #DEDEDE;
    filter: invert(10%); }

.menu-item.selected {
  border-bottom: 4px solid #D97225;
  color: #D97225; }
  @media only screen and (max-width: 64em) {
    .menu-item.selected {
      border-bottom: 4px solid #131313; } }

.divider {
  flex: 1; }
  @media only screen and (max-width: 64em) {
    .divider {
      display: none; } }

.user-container {
  display: flex; }
  @media only screen and (max-width: 64em) {
    .user-container {
      min-width: 100%; } }

.user-button, .user-button--square {
  position: relative;
  min-width: 14rem;
  min-height: 3.5rem;
  background-color: #D97225;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  @media only screen and (max-width: 64em) {
    .user-button, .user-button--square {
      flex: 1 1 auto; } }
  .user-button:hover, .user-button--square:hover {
    filter: brightness(90%); }

.user-button--square {
  min-width: 3.5rem; }
  @media only screen and (max-width: 64em) {
    .user-button--square {
      max-width: 3.5rem;
      width: 3.5rem;
      min-width: 0px; } }

.user-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  font-size: 0.9rem; }

.user-name {
  color: white;
  font-weight: bold;
  line-height: 1rem;
  padding: 0 1rem; }

.user-customer {
  color: white;
  font-size: .8rem;
  line-height: 1rem;
  padding: 0 1rem;
  opacity: 0.8; }

.user-role {
  background: #ffffff5e;
  width: 18px;
  display: inline-block;
  height: 18px;
  margin-right: 4px;
  text-align: center;
  line-height: 14px;
  vertical-align: middle;
  border-radius: 4px; }

.icon {
  padding: 1rem;
  color: white; }

.tag {
  border-left: 4px solid #D97225; }

.tag-distributor {
  border-left: 4px solid #3169B0; }
