/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.header {
  margin-top: 1rem;
  position: relative;
  height: 7rem;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  padding: 1rem; }
  @media print {
    .header {
      background-color: unset;
      box-shadow: inset 0 0 0 2000px #fafafa; } }

.title {
  font-size: 2rem;
  letter-spacing: 0.2rem; }

.logo {
  position: absolute;
  top: 0.5rem;
  right: 0px;
  margin-right: 1rem; }
  .logo img {
    height: 6rem; }
