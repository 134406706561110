/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.code {
  font-family: monospace;
  padding: 2rem;
  background-color: #F7F7F7;
  user-select: text;
  overflow-x: auto; }
