/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.info span {
  color: #4A4A4A; }

.container {
  user-select: text !important;
  width: 100%;
  background: #F7F7F7;
  padding: 1rem;
  font-size: 0.8rem; }

.device {
  margin-top: 0.35rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .device span a:hover {
    background: #efefef; }

.connected, .disconnected {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #16b11d;
  display: inline-block;
  margin: 0 5px; }

.disconnected {
  background: #810707 !important; }

.code {
  font-family: monospace; }
