/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.yield {
  display: flex;
  flex-direction: column; }

.yield-header {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
  border-bottom: 2px solid #D97225;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.yield-row {
  border-radius: 0 0 5px 5px;
  font-size: 0.8rem;
  font-weight: bold;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px; }

.info span {
  color: #4A4A4A; }

.info-box {
  background-color: #DEDEDE;
  border-radius: 4px;
  padding: 1px 10px 10px 10px;
  margin-top: 10px;
  border: 2px solid #d0d0d0; }

.lybra {
  margin-top: 20px;
  overflow-x: auto;
  overflow-y: hidden; }

.lybra-header {
  display: flex;
  height: 20px;
  width: 100%;
  min-width: 36rem; }
  .lybra-header span {
    flex: 1; }

.lybra-inputs {
  display: flex;
  width: 100%;
  height: 90px;
  min-width: 36rem;
  border-top-width: 0px; }

.lybra-empty {
  height: 50px !important; }

.input-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DEDEDE; }

.lybra-cell {
  flex: 1; }
  .lybra-cell div {
    display: flex; }
  .lybra-cell span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border: 1px solid #DEDEDE;
    font-weight: bold;
    font-size: 0.8em; }
  .lybra-cell input[type=text], .lybra-cell input[type=email], .lybra-cell input[type=number], .lybra-cell input[type=password], .lybra-cell textarea {
    max-width: 80px;
    text-align: center !important;
    font-size: 1.1rem !important;
    background: none !important;
    border: none !important;
    padding-left: 25px; }

.warning {
  color: orange;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center; }

.info-box--warning {
  border: 2px solid orange; }

.lybra-center {
  height: 80px !important;
  font-weight: bold;
  font-size: 1.2em !important; }

.lybra-std-center {
  height: 50px !important;
  font-weight: bold;
  font-size: 1.2em !important; }

.blue {
  background-color: #5b8ccc; }
  @media print {
    .blue {
      background-color: unset;
      box-shadow: inset 0 0 0 1500px #5b8ccc; } }

.green, .green-wide {
  background-color: #95bf75; }
  @media print {
    .green, .green-wide {
      background-color: unset;
      box-shadow: inset 0 0 0 1500px #95bf75; } }

.green-wide {
  flex: 2 !important; }

.red {
  background-color: #e25252; }
  @media print {
    .red {
      background-color: unset;
      box-shadow: inset 0 0 0 1500px #e25252; } }

.correction {
  display: flex;
  align-content: center;
  justify-content: center; }
  .correction img {
    width: 42px;
    height: 42px; }
