/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
  border: 1px solid #DEDEDE;
  border-radius: 3px;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #4A4A4A;
  letter-spacing: 0;
  padding: 0.8rem;
  background-image: none !important;
  margin-top: 1.25rem;
  cursor: pointer; }

.check {
  font-size: 1rem; }
