/* base colors */
/* colors */
/* base */
/* layout */
/* icon */
/* font */
/* button */
/* input */
.container, .container--full-page {
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  background: #FAFAFA;
  box-shadow: 0 2px 4px #cecece;
  border-radius: 3px;
  margin-bottom: 1.5rem; }

.container--print {
  box-shadow: none;
  overflow-x: visible; }

.container--full-page {
  box-shadow: none;
  overflow-x: visible; }

.item, .header, .placeholder {
  padding: 0.45rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem; }
  .item:hover, .header:hover, .placeholder:hover {
    background-color: #f3f3f3; }

.header {
  color: #D97225; }

.alarm {
  color: #e25252; }

.status {
  font-size: 1.2rem;
  color: #76B45C; }

.title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem; }

.detail {
  color: #9B9B9B; }

.more {
  float: left;
  cursor: pointer;
  color: #4A4A4A;
  font-style: italic;
  font-size: 0.8rem;
  margin-top: -1rem; }

.footer {
  float: left;
  color: #4A4A4A;
  font-style: italic;
  font-size: 0.8rem;
  margin-top: -1rem; }

.table-container {
  overflow-x: auto;
  width: 100%; }

@media only screen and (max-width: 64em) {
  .table-row-large {
    min-width: 100rem; } }

@media only screen and (max-width: 64em) {
  .table-row-small {
    min-width: 60rem; } }

.table-column, .table-column-large {
  flex: 1;
  min-width: 8rem; }

.table-column--small {
  flex: 1;
  min-width: 4rem; }

.table-column--xsmall {
  flex: 1;
  min-width: 2rem; }

.table-column--xsmall__action {
  flex: 1;
  min-width: 2rem;
  color: #D97225; }

.table-column-large {
  flex: 3;
  min-width: 25rem; }

.table-column-delete {
  font-size: 1.1rem;
  text-align: center; }
  .table-column-delete:hover {
    background-color: #e25252;
    border-radius: 4px;
    color: white;
    cursor: pointer; }

.table-column-edit {
  font-size: 1.1rem;
  text-align: center; }
  .table-column-edit:hover {
    background-color: #3169B0;
    border-radius: 4px;
    color: white;
    cursor: pointer; }

.table-inner-detail {
  background: #fafafa;
  padding: 1rem;
  font-size: 0.8rem; }

@media only screen and (min-width: 40.063em) {
  .container--print .table-column, .container--print .table-column-large, .container--full-page .table-column, .container--full-page .table-column-large {
    min-width: 0; } }

.form-container {
  width: 100%;
  margin-top: 1rem;
  background-color: #F7F7F7;
  padding: 1rem; }

.inline-button, .inline-button--bottom {
  float: right;
  color: #D97225 !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer; }

.inline-button--bottom {
  margin-top: -1rem; }

.placeholder {
  color: #9B9B9B;
  font-style: italic; }
